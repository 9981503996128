import VueRouter from 'vue-router'
// 引入组件
import wecomoaTrip from '../pages/wecomoaTrip'
import grinderStatus from '../pages/grinderStatus'
import caiWuYuSuan from '../pages/caiWuYuSuan'
import gongZiJiangJin from '../pages/gongZiJiangJin'

const router = new VueRouter({
	routes: [{
			path: '/wecomoaTrip',
			component: wecomoaTrip
		},
		{
			path: '/grinderStatus',
			component: grinderStatus
		},
		{
			path: '/caiWuYuSuan',
			component: caiWuYuSuan
		}, {
			path: '/gongZiJiangJin',
			component: gongZiJiangJin
		}
		// {
		// 	path: '/home',
		// 	component: home,
		// 	children: [{
		// 		path: 'message',
		// 		component: message,
		// 		children: [{
		// 			name: 'detail',
		// 			path: 'detail',
		// 			component: detail
		// 		}]
		// 	}]
		// }
	]
})

export default router
