<template>
	<div>
		<el-empty description="打磨车状态，开发中……"></el-empty>
	</div>
</template>

<script>
	export default {
		name: "GrinderStatus", //组件名称是App
		components: {},
		data() {
			return {

			}
		},
		methods: {

		},
		mounted() {
			console.log(this.$route)
		},
	}
</script>

<style scoped>
	div{
		text-align: center;
	}
</style>
