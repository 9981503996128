<template>
	<div>

		<el-empty description="工资奖金，开发中……"></el-empty>
	</div>
</template>

<script>
	export default {
		name: "GongZiJiangJin", //组件名称是App
		components: {},
		data() {
			return {

			}
		},
		methods: {

		},
		mounted() {
			// console.log(this.$route)
		},
	}
</script>

<style scoped>
	div{
		text-align: center;
	}
</style>