<template>
	<div id="app">
		<el-row class="tac">
			<el-col :span="3">
				<div id="leftTitle">
					<h2>同和时代</h2>
				</div>
				<el-menu default-active="/wecomoaTrip" class="el-menu-vertical-demo" @open="handleOpen"
					@close="handleClose" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b"
					router>
					<el-menu-item index="/wecomoaTrip">
						<i class="el-icon-menu"></i>
						<span slot="title">打磨差补</span>
						<!-- <router-link slot="title" :to="{
							path:'/wecomoaTrip',
							query:{
								url:'/wecomoaTrip'
							}
						}">打磨差补</router-link> -->
					</el-menu-item>
					<el-menu-item index="/grinderStatus">
						<i class="el-icon-menu"></i>
						<span slot="title">设备状态</span>
						<!-- <router-link slot="title" to="/grinderStatus?a=2">设备状态</router-link> -->
					</el-menu-item>
					<el-menu-item index="/caiWuYuSuan">
						<i class="el-icon-menu"></i>
						<span slot="title">财务预算</span>
					</el-menu-item>
					<el-menu-item index="/gongZiJiangJin">
						<i class="el-icon-menu"></i>
						<span slot="title">工资奖金</span>
					</el-menu-item>
				</el-menu>
			</el-col>
			<el-col :span="21">
				<!-- <wecomoaTrip /> -->
				<router-view></router-view>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	export default {
		name: 'App',
		components: {},
		methods: {
			handleOpen(key, keyPath) {
				console.log(key, keyPath);
			},
			handleClose(key, keyPath) {
				console.log(key, keyPath);
			}
		},
	}
</script>

<style scoped>
	#leftTitle {
		text-align: center;
	}
</style>
