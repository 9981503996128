<template>
	<div>
		<el-container>
			<el-header>
				<h2>打磨服务差补统计</h2>
			</el-header>
			<el-main>
				<div class="block">
					<span class="demonstration">请选择要查看的日期区间</span>
					<el-date-picker v-model="dateArr" type="daterange" range-separator="至" start-placeholder="开始日期"
						end-placeholder="结束日期" format="yyyy 年 MM 月 dd 日" value-format="timestamp"
						:default-time="['00:00:00', '23:59:59']">
					</el-date-picker>
					<el-button type="primary" @click="getTableData" :disabled="loading">查询</el-button>
				</div>
				<!-- <h3>{{dateArr}}</h3> -->
				<el-table :data="tableData" border show-summary style="width: 100%" v-loading="loading">
					<el-table-column align="center" prop="userName" label="姓名" min-width>
					</el-table-column>
					<el-table-column align="center" prop="workType0" label="0" min-width>
					</el-table-column>
					<el-table-column align="center" prop="workType5" label="0.5" min-width>
					</el-table-column>
					<el-table-column align="center" prop="workType8" label="0.8" min-width>
					</el-table-column>
					<el-table-column align="center" prop="workType10" label="1.0" min-width>
					</el-table-column>
					<el-table-column align="center" prop="workType12" label="1.2" min-width>
					</el-table-column>
					<el-table-column align="center" prop="totalWorkDays" label="出差天数" min-width>
					</el-table-column>
					<el-table-column align="center" prop="totalWorkDaysByType" label="差补天数" min-width>
					</el-table-column>
					<el-table-column label="操作" align="center" min-width>
						<template slot-scope="scope">
							<el-popover placement="left" width="751" title="详细申请记录如下:" trigger="click">
								<el-table :data="userData" border show-summary>
									<el-table-column width="150" align="center" property="apply_time" label="申请日期">
									</el-table-column>
									<el-table-column width="150" align="center" property="tripDate" label="出差日期">
									</el-table-column>
									<el-table-column width="150" align="center" property="workDays" label="出差天数">
									</el-table-column>
									<el-table-column width="150" align="center" property="workType" label="差补类型">
									</el-table-column>
									<el-table-column width="150" align="center" property="WorkDaysByType" label="差补天数">
									</el-table-column>
								</el-table>
								<el-button slot="reference" @click="handleClick(scope.row)" type="text" size="small">
									查看详情</el-button>
							</el-popover>
							<!-- <el-button @click="handleClick(scope.row)" type="text" size="small">查看详情</el-button> -->
						</template>
					</el-table-column>
				</el-table>
			</el-main>
			<el-footer>
				<h3>北京同和时代科技有限公司 Beijing The-Time Technology Co., LTD.</h3>
			</el-footer>
		</el-container>
	</div>
</template>

<script>
	export default {
		// 对外输出App组件：export default {}
		name: "wecomoa", //组件名称是App
		components: {},
		data() {
			return {
				loading: false,
				dateArr: '',
				tableData: [],
				userData: []
			}
		},
		methods: {
			handleClick(row) {
				console.log(row.userData)
				for (let i = 0; i < row.userData.length; i++) {
					row.userData[i].tripDate = row.userData[i].beginMonth + '.' + row.userData[i].beginDay + ' - ' + row
						.userData[i].endMonth + '.' + row.userData[i].endDay
					row.userData[i].WorkDaysByType = parseFloat(parseInt(row.userData[i].workDays) * parseFloat(row
						.userData[i].workType)).toFixed(1)
				}
				this.userData = row.userData
			},
			async getTableData() {
				var url = '/wecomoa/grindingTripServer' //同一个服务器就用相对路径
				// var url = 'http://localhost:8080/api/wecomoa/grindingTripServer' //测试临时路径
				let startDate = this.dateArr[0]
				let stopDate = this.dateArr[1]
				var event = {
					dbName: 'wecomoa',
					startTime: startDate / 1000,
					stopTime: stopDate / 1000,
					templateId: 'C4Nufq2gssUgwsYW6wncw5Hp5EajsHeBJDiSgG1xP'
				}
				this.loading = true
				this.$axios.get(url, {
					params: event
				}).then(res => {
					// console.log(res)
					if (res.data.length > 0) {
						let tmp = res.data
						for (let i = 0; i < tmp.length; i++) {
							if (tmp[i].workType0 == 0) {
								tmp[i].workType0 = '-'
							}
							if (tmp[i].workType5 == 0) {
								tmp[i].workType5 = '-'
							}
							if (tmp[i].workType8 == 0) {
								tmp[i].workType8 = '-'
							}
							if (tmp[i].workType10 == 0) {
								tmp[i].workType10 = '-'
							}
							if (tmp[i].workType12 == 0) {
								tmp[i].workType12 = '-'
							}
						}
						this.tableData = tmp
						this.loading = false
						this.$message({
							message: '查询成功！',
							type: 'success'
						});
					} else {
						this.tableData = res.data
						this.loading = false
						this.$message({
							message: '无记录！',
							type: 'success'
						});
					}
				}).catch(err => {
					console.log(err)
					this.$message.error('查询失败！');
				})
			}
		},
		mounted() {
			console.log(this.$route)
		}
	}
</script>

<style scoped>
	.el-header,
	.el-footer {
		background-color: #B3C0D1;
		color: #333;
		text-align: center;
	}

	.el-main {
		background-color: #E9EEF3;
		color: #333;
		text-align: center;
	}

	.el-button {
		margin-left: 10px;
	}
</style>
